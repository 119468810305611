<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px" disabled>
					<el-form-item label="部门" prop="department_id" >
						<form-api v-model="form.department_id" :api="department.all"/>
					</el-form-item>
					<el-form-item label="设备名称" prop="good_id" >
						<form-api v-model="form.good_id" :api="good.all"/>
					</el-form-item>
					<el-form-item label="报废说明" prop="message">
						<el-input type="textarea" v-model="form.message"/>
					</el-form-item>
					<el-form-item label="图片" prop="image">
						<form-upload v-model="form.image" :limit="9" />
					</el-form-item>
					<el-form-item label="视频" prop="video">
						<form-upload v-model="form.video" accept="video" />
					</el-form-item>
					<div class="form-flex">
						<el-form-item label="申请人" prop="user_id" >
							<form-api v-model="form.user_id" :api="user.all" title="username" render="{username}（{phone}）"/>
						</el-form-item>
						<el-form-item label="申请时间" prop="create_time" >
							<form-date-picker v-model="form.create_time"/>
						</el-form-item>
					</div>
					<template v-if="form.status === 2">
						<el-form-item label="主管审批状态" prop="check1_status">
							<el-radio-group v-model="form.check1_status" >
								<el-radio v-for="(item,index) in column_option.check1_status" :key="index" :label="item.id">{{item.title}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="主管审批说明" prop="check1_message">
							<el-input type="textarea" v-model="form.check1_message" />
						</el-form-item>
						<div class="form-flex">
							<el-form-item label="审批人" prop="check1_user_id" >
								<form-api v-model="form.check1_user_id" :api="user.all" title="username" render="{username}（{phone}）" />
							</el-form-item>
							<el-form-item label="审批时间" prop="create_time" >
								<form-date-picker v-model="form.check1_time"/>
							</el-form-item>
						</div>
					</template>
					<template v-if="form.status === 3">
						<el-form-item label="管理员审批状态" prop="check2_status">
							<el-radio-group v-model="form.check2_status" >
								<el-radio v-for="(item,index) in column_option.check2_status" :key="index" :label="item.id">{{item.title}}</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="管理员审批说明" prop="check2_message">
							<el-input type="textarea" v-model="form.check2_message" />
						</el-form-item>
						<el-form-item label="审批时间" prop="create_time" >
							<form-date-picker v-model="form.check2_time"/>
						</el-form-item>
					</template>
					<el-form-item label="状态" prop="status">
						<el-radio-group v-model="form.status" disabled>
							<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{item.title}}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">关闭</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {department, drop as api, good,user} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
			good,
			user,
			department,
		}
	},
	computed:{
		rules(){
			return {

			}
		}
	},
}
</script>
