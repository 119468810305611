<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px" >
					<el-form-item label="审批意见" prop="check2_status">
						<el-radio-group v-model="form.check2_status" >
							<el-radio v-for="(item,index) in check2_status" :key="index" :label="item.id">{{item.title}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="审批说明" prop="check2_message">
						<el-input type="textarea" v-model="form.check2_message" />
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {department, drop as api, good,user} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
import _ from "lodash";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
			good,
			user,
			department,
			check2_status: [{id: 1, title: "同意"}, {id: 2, title: "拒绝"}],
		}
	},
	computed:{
		rules(){
			return {
				check2_status:[
					{required:true,message:"请选择审批意见"}
				]
			}
		}
	},
	methods:{
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				const data = this.encodeData(_.cloneDeep(this.form));

				this.submit_loading = true;
				this.api.check(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'操作成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		}
	}
}
</script>
