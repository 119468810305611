<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}"></template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" v-if="scope.row.check1_status === 1 && scope.row.status === 2" @click="check(scope.row)">审批</el-link>
							<el-link type="primary" @click="editor(scope.row)">详情</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary">删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		<components-create v-if="create_visible" ref="createRef" :title="title" :column_option="column_option" @success="all" @close="closeCreate"/>
		<components-check v-if="check_visible" ref="checkRef" :column_option="column_option" @success="all" @close="closeCheck"/>
	</main>
</template>
<script>
import ComponentsCreate from "./Create.vue"
import ComponentsCheck from "./Check.vue"
import {MixinIndex} from "@/service/admin/mixinIndex";
import {drop as api, good, user,department} from "@/api/admin";

export default {
	components: {ComponentsCreate,ComponentsCheck},
	mixins: [MixinIndex],
	data() {
		return {
			api,
			title: "报废申请",
			column_option: {
				check1_status: [{id: 1, title: "已同意"}, {id: 2, title: "已拒绝"}],
				check2_status: [{id: 1, title: "已同意"}, {id: 2, title: "已拒绝"}],
				status: [{id: 1, title: "待审批"}, {id: 2, title: "主管审批"}, {id: 3, title: "管理员审批"}],
			},

			check_visible:false,
		}
	},
	computed: {
		column() {
			return [
				{label: "ID", prop: "id",  sortable: true},
				{label: "部门", prop: "department.title", type:'api',option:{api:department.all,field:'department_id'}},
				{label: "设备名称", prop: "good.title", search: true,type:'api',option:{api:good.all,field:'good_id'}},
				{label: "设备编号", prop: "good.code"},
				{label: "说明", prop: "message"},
				{label: "主管审批状态", prop: "check1_status", type: "option", search: true, option: this.column_option.check1_status},
				{label: "主管审批留言", prop: "check1_message"},
				{label: "主管审批时间", prop: "check1_time",type:"datetime"},
				{label: "管理员审批状态", prop: "check2_status", type: "option", search: true, option: this.column_option.check2_status},
				{label: "管理员审批留言", prop: "check2_message"},
				{label: "管理员审批时间", prop: "check2_time",type:"datetime"},
				{label: "申请人姓名", prop: "user.username", type:'api',option:{api:user.all,field:'user_id'}},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "申请时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "操作", prop: "action", fixed: 'right', type: 'action'},
			]
		}
	},
	methods:{
		check(row){
			this.check_visible = true;
			this.$nextTick(()=>{
				this.$refs.checkRef.init(row);
			})
		},
		closeCheck(){
			this.check_visible = false;
		}
	}
}
</script>
